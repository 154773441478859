<template>
  <div class="attention baseConsoleTabPage accountRole">
    <div class="tabBlock">
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('companyAccountManage')"
          name="account"
        ></el-tab-pane>
        <el-tab-pane :label="$t('companyRoleManage')" name="role"></el-tab-pane>
      </el-tabs>
      <div
        class="addBtn"
        v-if="activeName === 'account' && USER_INFO.is_admin === 1"
      >
        <el-button size="small" type="primary" icon="el-icon-plus" @click="add">
          {{ $t("addAccount") }}
        </el-button>
      </div>
      <div class="addBtn" v-if="activeName === 'role'">
        <el-popover
          placement="bottom"
          width="300"
          trigger="click"
          class="precautionspopover"
        >
          <div>
            <ul>
              <li>{{ $t("totheadministratoraccount") }}；</li>
              <li>{{ $t("administratorroleischange") }}；</li>
              <li>{{ $t("correspondtomultipleroles") }}；</li>
            </ul>
          </div>
          <el-button slot="reference" size="small" type="primary">
            {{ $t("Precautions") }}
          </el-button>
        </el-popover>
      </div>
    </div>
    <div class="tableBlock" v-if="activeName === 'account'">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100"
        ref="tableDataRef"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="tableDataLoading"
      >
        <el-table-column prop="date" :label="$t('account')" min-width="180px">
          <template slot-scope="scope">
            <div class="userInfo">
              <div class="img" @click="openUserInfoDetail(scope.row)">
                <el-image
                  style="width: 50px; height: 50px; border-radius: 50%"
                  :src="scope.row.avatar ? scope.row.avatar : DEFAULT_AVATAR"
                  class="userImg"
                  fit="cover"
                ></el-image>
              </div>
              <div class="name">
                <div
                  class="userName textOverflow"
                  @click="openUserInfoDetail(scope.row)"
                >
                  {{
                    scope.row.name_zh
                  }}
                </div>
                <div class="title textOverflow">
                  {{
                    scope.row.department
                     
                  }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('accountType')"
          :show-overflow-tooltip="true"
          min-width="120px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.is_admin == 0">
              {{ $t("subAccount") }}
            </span>
            <span v-else-if="scope.row.is_admin == 1">
              {{ $t("mainAccount") }}
            </span>
            <span v-else>
              {{ scope.row.is_admin | textFormat }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('accountStatus')"
          min-width="150px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.user_status == 0">
              <span
                style="
                  display: inline-block;
                  width: 7px;
                  height: 7px;
                  background: #1890ff;
                  border-radius: 50%;
                "
              ></span>
              <span style="line-height: 23px; font-size: 12px">
                {{ $t("notActivated") }}
              </span>
            </div>
            <div
              v-else-if="
                scope.row.user_status === 1 &&
                scope.row.company_user_rel_status == 1
              "
            >
              <span
                style="
                  display: inline-block;
                  width: 7px;
                  height: 7px;
                  background: #fe5500;
                  border-radius: 50%;
                "
              ></span>
              <span style="line-height: 23px; font-size: 12px; color: #fe5500">
                {{ $t("pending") }}
              </span>
            </div>
            <div
              v-else-if="
                scope.row.user_status === 1 &&
                scope.row.company_user_rel_status === 2
              "
            >
              <span
                style="
                  display: inline-block;
                  width: 7px;
                  height: 7px;
                  background: #2eba07;
                  border-radius: 50%;
                "
              ></span>
              <span style="line-height: 23px; font-size: 12px; color: #2eba07">
                {{ $t("normal") }}
              </span>
            </div>
            <div v-else-if="scope.row.user_status === 2">
              <span
                style="
                  display: inline-block;
                  width: 7px;
                  height: 7px;
                  background: #555555;
                  border-radius: 50%;
                "
              ></span>
              <span style="line-height: 23px; font-size: 12px; color: #555555">
                {{ $t("disabledbtn") }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('createTime')"
          min-width="200px"
        >
          <template slot-scope="scope">
            {{ scope.row.created_time | secondFormat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('handle')"
          width="220px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.user_status == 0">
              <el-button
                type="text"
                size="small"
                @click="editAccountInfo(scope.row)"
                v-if="inspectButton('coi:acm:mda')"
                :disabled="inspectHandle('coi:acm:mda')"
                >{{ $t("modify") }}
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="deleteAccount(scope.row)"
                v-if="inspectButton('coi:acm:del')"
                :disabled="inspectHandle('coi:acm:del')"
                >{{ $t("delete") }}
              </el-button>
            </div>
            <div
              v-else-if="
                scope.row.user_status === 1 &&
                scope.row.company_user_rel_status == 1
              "
            >
              <el-button
                type="text"
                size="small"
                @click="auditBtn(scope.row, 2)"
                >{{ $t("through") }}
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="auditBtn(scope.row, 3)"
                >{{ $t("refuse") }}
              </el-button>
            </div>
            <div
              v-else-if="
                scope.row.user_status === 1 &&
                scope.row.company_user_rel_status == 2 &&
                scope.row.is_admin === 1
              "
            >
              <el-button
                type="text"
                size="small"
                @click="editAccountInfo(scope.row)"
                v-if="inspectButton('coi:acm:mda')"
                :disabled="inspectHandle('coi:acm:mda')"
                >{{ $t("modify") }}
              </el-button>
            </div>
            <div
              v-else-if="
                scope.row.user_status === 1 &&
                scope.row.company_user_rel_status == 2 &&
                scope.row.is_admin === 0
              "
            >
              <el-button
                type="text"
                size="small"
                @click="editAccountInfo(scope.row)"
                v-if="inspectButton('coi:acm:mda')"
                :disabled="inspectHandle('coi:acm:mda')"
                >{{ $t("modify") }}
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="setAdminAccount(scope.row)"
                v-if="USER_INFO.is_admin === 1"
                >{{ $t("Become Admin") }}
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="unbindBtn(scope.row)"
                v-if="inspectButton('coi:acm:unb')"
                :disabled="inspectHandle('coi:acm:unb')"
                >{{ $t("Unbind") }}
              </el-button>
            </div>
            <div v-if="scope.row.user_status === 2">
              <el-button
                type="text"
                size="small"
                @click="editAccountInfo(scope.row)"
                v-if="inspectButton('coi:acm:mda')"
                :disabled="inspectHandle('coi:acm:mda')"
                >{{ $t("modify") }}
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="unbindBtn(scope.row)"
                v-if="inspectButton('coi:acm:unb')"
                :disabled="inspectHandle('coi:acm:unb')"
                >{{ $t("Unbind") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tableBlock" v-show="activeName === 'role'">
      <el-table
        v-if="activeName === 'role'"
        ref="roleTableDataRef"
        :data="roleTableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="roleTableDataLoading"
      >
        <el-table-column
          prop="date"
          :label="$t('roleName')"
          min-width="80px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.role_name  }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="name"
          :label="$t('roleDesc')"
          width="400px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.role_remarks | textFormat }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="address"
          :label="$t('accountNumber')"
          min-width="90px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div class="userInfo">
              <div class="img" @click="openUserInfoDetail(scope.row)">
                <el-image
                  style="width: 50px; height: 50px; border-radius: 50%"
                  :src="scope.row.avatar ? scope.row.avatar : DEFAULT_AVATAR"
                  class="userImg"
                  fit="cover"
                ></el-image>
              </div>
              <div class="name">
                <div
                  class="userName textOverflow"
                  @click="openUserInfoDetail(scope.row)"
                >
                  {{
                    scope.row.name_zh
                  }}
                </div>
                <!-- <div class="title textOverflow">
                  {{
                    scope.row.department | textFormat()
                     
                  }}
                </div> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('createTime')"
          width="200px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.created_time | secondFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('handle')"
          min-width="50px"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editRoleBtn(scope.row)"
              >{{ $t("modify") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
        :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
    <!--添加账号-->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="addAccountDialog"
      class="add-account onlyBorderBottomForm"
      width="550px"
      @close="resetForm('ruleForm')"
    >
      <el-form
        :validate-on-rule-change="false"
        :model="userInfoForm"
        :rules="rules"
        ref="ruleForm"
        :label-width="LOCALE == 'en' ? '170px' : '140px'"
        class="demo-ruleForm"
      >
        <div>
          <el-form-item
            class="item"
            :label="`${$t('name')}${$t('colon')}`"
            prop="name"
          >
            <el-input
              size="small"
              v-model="userInfoForm.name"
              style="width: 270px"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item
            class="item itememail"
            :label="`${$t('email')}${$t('colon')}`"
            prop="email"
          >
            <el-input
              size="small"
              v-model="userInfoForm.email"
              style="width: 270px"
            ></el-input>
          </el-form-item>
        </div>
        <div></div>
        <el-form-item
          class="item"
          :label="`${$t('phone')}${$t('colon')}`"
          prop="phone"
          
         
        >
          <el-input
            @change="checkPhone"
            :disabled="disabledInput"
            size="small"
            v-model="userInfoForm.phone"
            style="width: 270px; position: relative; top: 4px"
          >
            <el-select
              filterable
              placeholder
              :disabled="disabledInput"
              v-model="userInfoForm.area_code"
              slot="prepend"
              class="area-select"
              popper-class="registerAreaCodeList"
              :class="{ areadisabled: addOrEdit == 1 }"
              :filter-method="areaCodeFilter"
            >
              <el-option
                v-for="(item, index) in areaCodeList"
                :key="item.area_code + index + ''"
                :value="item.area_code"
                :title="item.country | areacodeTitleFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
          <!-- <p class="telTips" v-if="telTipsShow">手机号已存在</p> -->

        <!-- <el-form-item
          class="item"
          :label="`${$t('gender')}${$t('colon')}`"
          prop="sex"
         >
          <el-select
            placeholder
            size="small"
            v-model="userInfoForm.sex"
            style="width: 270px"
          >
            <el-option
              v-for="item in genderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item
          style="margin-top:15px"
          class="item"
          label="部门"
          prop="companyTitle"
        >
          <el-input
            size="small"
            v-model="userInfoForm.companyTitle"
            style="width: 270px"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          :loading="saveBtnLoading"
          size="small"
          type="primary"
          @click="saveNewAccount('ruleForm')"
          >{{ $t("save") }}
        </el-button>
      </div>
    </el-dialog>
    <!--删除帐号-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('deleteAccount')"
      :visible.sync="deleteDialog"
      width="400px"
    >
      <span>{{ this.deleteTitle }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="deleteDialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          :loading="deleteConfirmBtnLoading"
          size="small"
          type="primary"
          @click="confirmDeleteAccount"
          >{{ $t("confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!--解除绑定-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('unbind')"
      :visible.sync="unbindDialog"
      width="400px"
    >
      <span>
        确认解绑
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="unbindDialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          :loading="unbindBtnLoading"
          size="small"
          type="primary"
          @click="confirmunbindAccount"
          >{{ $t("confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!--审核帐号-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('accountAudit')"
      :visible.sync="accountAuditDialog"
      width="400px"
    >
      <span>{{ this.accountAuditTitle }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="accountAuditLoading"
          size="small"
          @click="refuseJoinCompany"
          >{{ $t("refuse") }}</el-button
        >
        <el-button
          :loading="accountAuditLoading"
          size="small"
          type="primary"
          @click="agreeJoinCompany"
          >{{ $t("through") }}</el-button
        >
      </span>
    </el-dialog>
    <!--修改角色-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('modifyRoles')"
      :visible.sync="modifyRolesDialog"
      width="430px"
      @close="refuseEditRole('modifyRolesForm')"
    >
      <el-form
        :model="modifyRolesForm"
        :rules="modifyRolesFormrules"
        ref="modifyRolesForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('changeAccount')" prop="role">
          <el-select size="small" v-model="modifyRolesForm.role">
            <el-option
              v-for="item in roleList"
              :key="item.user_id"
              :label="item.name_en | priorFormat(item.name_zh, LOCALE)"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="refuseEditRole('modifyRolesForm')">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          :loading="modifypartBtnLoading"
          size="small"
          type="primary"
          @click="agreeEditRole('modifyRolesForm')"
          >{{ $t("confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!--查看账号详情-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('accountdetails')"
      :visible.sync="accountDetail"
      width="430px"
    >
      <div class="user-detail">
        <div class="item">
          <div class="key">
            {{ $t("name") }}
          </div>
          <div class="value">
            {{ currentItem.name_en | priorFormat(currentItem.name_zh, LOCALE) }}
          </div>
        </div>
        <div class="item">
          <div class="key">
            {{ $t("email") }}
          </div>
          <div class="value">
            {{ currentItem.email | textFormat }}
          </div>
        </div>
        <div class="item">
          <div class="key">
            {{ $t("phone") }}
          </div>
          <div class="value">
            {{ currentItem.area_code | textFormat }}
            {{ currentItem.mobile | textFormat }}
          </div>
        </div>
        <!-- <div class="item">
          <div class="key">
            {{ $t("gender") }}
          </div>
          <div class="value">
            <span v-if="currentItem.sex === 1">{{ $t("male") }}</span>
            <span v-else-if="currentItem.sex === 2">{{ $t("female") }}</span>
            <span v-else>-</span>
          </div>
        </div> -->
        <!-- <div class="item">
          <div class="key">
            部门
          </div>
          <div class="value">
            {{
              currentItem.department
                
            }}
          </div>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validateEmail, mobile } from "~/baseUtils/validate";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import { priorFormat } from "~/basePlugins/filters";

export default {
  data() {
    var checkEmail = async (rule, value, callback) => {
      console.log(value);
      if (value === "" || value === undefined || value === null) {
       callback();
      } else if (!validateEmail(value)) {
        callback(new Error(this.$t("wrongEmailFormat")));
      } else {
        callback();
        // try {
        //   let result = await this.$store.dispatch(
        //     "baseConsole/user_checkIdentifier",
        //     { identifier: value, type: 1, source: this.PJSource }
        //   );
        //   console.log(result);
        //   if (result.success) {
        //     callback();
        //   } else {
        //     if (result.errorcode == 1000) {
        //       this.emailCetshow = true;
        //     }
        //     callback(new Error(this.$t("emailExist")));
        //   }
        // } catch (e) {
         
        //   this.$seTip();
        // }
      }
    };
    var checkPhone = async (rule, value, callback) => {
      console.log(value);
      if (value === "" || value === undefined || value === null) {
       callback(new Error(' '));
      
      } else {
        
           let result = await this.$store.dispatch(
            "baseConsole/user_checkPhoneHas",
            { mobile:value }
          );
          console.log(result);
          if (result.success) {
            callback();
          } else {

            callback(new Error('手机号已被注册'));
          }
        // try {
        //   let result = await this.$store.dispatch(
        //     "baseConsole/user_checkIdentifier",
        //     { identifier: value, type: 1, source: this.PJSource }
        //   );
        //   console.log(result);
        //   if (result.success) {
        //     callback();
        //   } else {
        //     if (result.errorcode == 1000) {
        //       this.emailCetshow = true;
        //     }
        //     callback(new Error(this.$t("emailExist")));
        //   }
        // } catch (e) {
         
        //   this.$seTip();
        // }
      }
    };
    return {
      telTipsShow:false,
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      activeName: "account",
      tableData: [],
      tableDataLoading: false,
      roleTableData: [],
      roleTableDataLoading: false,
      total: 0,
      currentPage: 1,
      company_id: null,
      user_id: null,
      title: "",
      addAccountDialog: false,
      userInfoForm: {
        name: "",
        email: "",
        phone: "",
        sex: "",
        companyTitle: "",
        area_code: "",
        userId: "",
      },
      userInfoRule: {
        name: [{ required: true, message: " ", trigger: "blur" }],
        email: [
         
          { validator: checkEmail, trigger: "blur" },
        ],
         phone: [
         { required: true, message: " ", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        // companyTitle: [{ required: true, message: " ", trigger: "blur" }],
      },
      rules: {},
      disabledInput: false,
      //修改账号还是添加帐号
      addOrEdit: 0,
      genderOptions: [
        {
          value: "1",
          label: this.$t("male"),
        },
        {
          value: "2",
          label: this.$t("female"),
        },
      ],
      saveBtnLoading: false,
      nowUser: {},
      deleteTitle: "",
      deleteDialog: false,
      deleteConfirmBtnLoading: false,
      unbindDialog: false,
      unbindBtnLoading: false,
      accountAuditDialog: false,
      accountAuditTitle: "",
      accountAuditLoading: false,
      mowRoleUser: {},
      modifyRolesDialog: false,
      modifyRolesForm: {
        role: "",
      },
      modifyRolesFormrules: {
        role: [{ required: true, message: " ", trigger: "blur" }],
      },
      roleList: [],
      modifypartBtnLoading: false,
      tableHeight: "",
      accountDetail: false,
      currentItem: {},
    };
  },

  watch: {
    currentPage() {
      if (this.activeName === "account") {
        this.getAccountList();
      } else {
        this.getRoleList();
      }
    },
    activeName(nv) {
      this.total = 0;
      this.currentPage = 1;
      if (nv === "account") {
        this.$nextTick(() => {
          this.$refs.tableDataRef.doLayout();
        });
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.account.company",
          content: JSON.stringify({
            company_id: this.USER_INFO.company_id || ''
          }),
        });
        this.getAccountList();
      } else {
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.account.role",
          content: JSON.stringify({
            company_id: this.USER_INFO.company_id || ''
          }),
        });
        var myEvent = new Event("resize");
        window.dispatchEvent(myEvent);
        // this.$nextTick(() => {
        //   this.$refs.roleTableDataRef.doLayout();
        // });
        this.getRoleList();
      }
    },
  },
  created() {
    this.company_id = this.$store.state.baseStore.userInfo.company_id;
    this.user_id = this.$store.state.baseStore.userId;
  },
  mounted() {
    this.$log({
      type: 2,
      topic: "ymp.workbench",
      id: "ymp.workbench.account",
      content: JSON.stringify({
        id: this.USER_INFO.company_id || ''
      }),
    });
    this.getAccountList();
  },
  methods: {
    //获取账号数据
    async getAccountList() {
      this.tableDataLoading = true;
      try {
        let params = {};
        params.company_id = this.company_id;
        params.start = this.currentPage - 1;
        params.limit = 10;
        params.nopage = 0;
        params.type = 1;
        let res = await this.$store.dispatch(
          "baseConsole/company_accountManage",
          params
        );
        if (res.success) {
          this.tableData = res.data;
          this.total = res.count;
          this.tableDataLoading = false;
        }
      } catch (error) {
        this.tableDataLoading = false;
      }
    },
    //获取角色数据
    async getRoleList() {
      if (!this.$store.state.baseStore.userInfo.is_admin) {
        return;
      }
      this.roleTableDataLoading = true;
      try {
        let params = {};
        params.company_id = this.company_id;
        params.start = this.currentPage - 1;
        params.limit = 10;
        params.nopage = 0;
        let res = await this.$store.dispatch(
          "baseConsole/company_roleManage",
          params
        );
        if (res.success) {
          this.roleTableData = res.data;
          this.roleTableDataLoading = false;
          this.total = res.count;
        }
      } catch (e) {
        this.roleTableDataLoading = false;
      }
    },
    add() {
      this.userInfoForm.name = "";
      this.userInfoForm.email = "";
      this.userInfoForm.phone = "";
      this.userInfoForm.sex = "1";
      this.userInfoForm.companyTitle = "";
      this.userInfoForm.area_code = "+86";
      this.userInfoForm.userId = "";
      this.addOrEdit = 0;
      this.disabledInput = false;
      this.addAccountDialog = true;
      this.title = `${this.$t("addAccount")}`;
      this.rules = this.userInfoRule;
    },
    checkPhone() {
      if (mobile(this.userInfoForm.phone, this.userInfoForm.area_code)) {
      } else {
        this.$message.error(this.$t("wrongTelephoneFormat"));
        this.userInfoForm.phone = "";
      }
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    //点击修改按钮
    editAccountInfo(data) {
      this.addOrEdit = 1;
      this.disabledInput = true;
      this.addAccountDialog = true;
      this.title = `${this.$t("editAccount")}`;
      this.userInfoForm.name = priorFormat(
        data.name_en,
        data.name_zh,
        this.LOCALE
      );
      this.userInfoForm.email = data.email;
      this.userInfoForm.phone = data.mobile;
      if (data.sex) {
        this.userInfoForm.sex = data.sex + "";
      }
      this.userInfoForm.companyTitle = data.department;
      this.userInfoForm.area_code = data.area_code;
      this.userInfoForm.userId = data.user_id;
      this.rules = this.editRules;
    },
   
    resetForm(formName) {
      
      this.$refs[formName].resetFields();
      
      this.addAccountDialog = false;
    },
    saveNewAccount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addCompanyAccount();
        } else {
          return false;
        }
      });
    },
    //添加帐号
    async addCompanyAccount() {
      if (this.addOrEdit) {
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.account.edit",
          content: JSON.stringify({
            company_id: this.USER_INFO.company_id || '',
            name: this.userInfoForm.name || '',
            email: this.userInfoForm.email || '',
            user_id: this.userInfoForm.userId
          }),
        });
        this.saveBtnLoading = true;
        let params = {};
        params.user_id = this.userInfoForm.userId;
        params.email = this.userInfoForm.email;
        params.area_code = this.userInfoForm.area_code;
        params.mobile = this.userInfoForm.phone;
        params.name_zh = this.userInfoForm.name;
        params.department = this.userInfoForm.companyTitle;
        // params.sex = this.userInfoForm.sex;
        params._NOCLEAR = true;
        // if (this.LOCALE == "zh") {
        //   params.name_zh = this.userInfoForm.name;
        //   params.job_title_zh = this.userInfoForm.companyTitle;
        // } else {
        //   params.name_en = this.userInfoForm.name;
        //   params.job_title_en = this.userInfoForm.companyTitle;
        // }
        try {
          let result = await this.$store.dispatch(
            "baseConsole/company_editAccountInfo",
            params
          );
          this.saveBtnLoading = false;
          this.addAccountDialog = false;
          this.getAccountList();
          if (result.success) {
            this.$ssTip();
          } else {
            this.$seTip();
          }
        } catch (error) {
          this.saveBtnLoading = false;
          this.addAccountDialog = false;
        }
      } else {
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.account.add",
          content: JSON.stringify({
            company_id: this.company_id || '',
            name: this.userInfoForm.name || '',
            email: this.userInfoForm.email || '',
            mobile: this.userInfoForm.phone || '',
          }),
        });
        this.saveBtnLoading = true;
        let params = {};
        params.company_id = this.company_id;
        params.email = this.userInfoForm.email;
         params.name_zh = this.userInfoForm.name;
        params.department = this.userInfoForm.companyTitle;
        // if (this.LOCALE == "zh") {
        //   params.name_zh = this.userInfoForm.name;
        //   params.job_title_zh = this.userInfoForm.companyTitle;
        // } else {
        //   params.name_en = this.userInfoForm.name;
        //   params.job_title_en = this.userInfoForm.companyTitle;
        // }
        params.area_code = this.userInfoForm.area_code;
        params.mobile = this.userInfoForm.phone;
        // params.sex = this.userInfoForm.sex;
        // params.source = this.PJSource;
        try {
          let result = await this.$store.dispatch(
            "baseConsole/company_AddAccount",
            params
          );
          this.saveBtnLoading = false;
          this.addAccountDialog = false;
          this.getAccountList();
          if (result.success) {
            this.$ssTip();
          } else {
            this.$seTip();
          }
        } catch (error) {
          this.saveBtnLoading = false;
          this.addAccountDialog = false;
        }
      }
    },
    //点击删除帐号
    async deleteAccount(data) {
      // this.nowUser = data;
      // let name = priorFormat(data.name_en, data.name_zh, this.LOCALE);
      // this.deleteTitle = `${this.$t("deleteTitle")}${name}${this.$t(
      //   "accountQuestion"
      // )}`;
      // this.deleteDialog = true;
      console.log(data);
      let params = {};
      params.user_id = data.user_id;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_deleteAccount",
          params
        );
        if (result.success) {
          this.$message({
            message: this.$t("delSuccess"),
            type: "success",
          });
          this.getAccountList();
        }
      } catch (error) {}
    },
    //确认删除
    async confirmDeleteAccount() {
      this.deleteConfirmBtnLoading = true;
      let params = {};
      params.user_id = this.nowUser.user_id;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_deleteAccount",
          params
        );
        if (result.success) {
          this.deleteConfirmBtnLoading = false;
          this.deleteDialog = false;
          this.getAccountList();
        }
      } catch (error) {
        this.deleteConfirmBtnLoading = false;
      }
    },
    //解除绑定
    unbindBtn(data) {
      this.nowUser = data;
      this.unbindDialog = true;
    },
    //确认解绑
    async confirmunbindAccount() {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.account.Unbound",
        content: JSON.stringify({
          company_id: this.company_id || '',
          user_id: this.nowUser.user_id
        }),
      });
      this.unbindBtnLoading = true;
      let params = {};
      params.company_id = this.company_id;
      params.user_id = this.nowUser.user_id;
      params.admin_user_id = this.user_id;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_unbindUser",
          params
        );
        if (result.success) {
          this.unbindBtnLoading = false;
          this.unbindDialog = false;
          this.getAccountList();
        }
      } catch (error) {
        this.unbindBtnLoading = false;
      }
    },
    //点击审核按钮
    async auditBtn(data, value) {
      // this.nowUser = data;
      // let name = priorFormat(data.name_en, data.name_zh, this.LOCALE);
      // this.accountAuditTitle = `${this.$t("accountAuditOne")}${name}${this.$t(
      //   "accountAuditTwo"
      // )}`;
      // this.accountAuditDialog = true;
      console.log(data);
      console.log(value);
      let params = {};
      params.company_id = data.company_id;
      params.user_id = data.user_id;
      params.status = value;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_applyJoin",
          params
        );
        if (result.success) {
          if (value === 2) {
            this.$message({
              message: this.$t("Successful_operation"),
              type: "success",
            });
          }
          this.getAccountList();
        }
      } catch (error) {}
    },
    //拒绝加入公司
    refuseJoinCompany() {
      this.auditAccountInfo(3);
    },
    //同意加入公司
    agreeJoinCompany() {
      this.auditAccountInfo(2);
    },
    //审核用户
    async auditAccountInfo(value) {
      this.accountAuditLoading = true;
      let params = {};
      params.company_id = this.company_id;
      params.user_id = this.nowUser.user_id;
      params.status = value;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_applyJoin",
          params
        );
        if (result.success) {
          this.accountAuditLoading = false;
          this.accountAuditDialog = false;
          this.getAccountList();
        }
      } catch (error) {
        this.accountAuditLoading = false;
      }
    },
    //点击角色修改
    editRoleBtn(data) {
      console.log(data);
      this.mowRoleUser = data;
      this.modifyRolesDialog = true;
      this.getRoleDistribution();
    },
    //获取可分配角色列表
    async getRoleDistribution() {
      let params = {
        company_id: this.company_id,
      };
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_distributionRole",
          params
        );
        console.log(result);
        if (result.success) {
          let data = result.data.filter(
            (item) => item.user_id !== this.mowRoleUser.user_id
          );
          this.roleList = data;
        }
      } catch (error) {}
    },
    async refuseEditRole(formName) {
      this.modifyRolesDialog = false;
      this.$refs[formName].resetFields();
    },
    async agreeEditRole(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.roleRelationshipEdit();
        } else {
          return false;
        }
        this.$refs[formName].resetFields();
      });
    },
    //用户角色关系修改
    async roleRelationshipEdit() {
      this.modifypartBtnLoading = true;
      let params = {};
      params.user_role_rel_id = this.mowRoleUser.user_role_rel_id;
      params.user_id = this.modifyRolesForm.role;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_roleRelationEdit",
          params
        );
        if (result.success) {
          this.modifypartBtnLoading = false;
          this.modifyRolesDialog = false;
          this.getRoleList();
          if (this.mowRoleUser.role_id === 34) {
            let res = await this.$store.dispatch(
              "baseConsole/user_getUserInfo",
              {
                user_id: this.USER_ID,
              }
            );
          }
        }
      } catch (error) {
        this.modifypartBtnLoading = false;
      }
    },
    openUserInfoDetail(item) {
      console.log(item);
      this.currentItem = item;
      this.accountDetail = true;
    },
    async setAdminAccount(item) {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.account.manage",
        content: JSON.stringify({
          company_id: item.company_id || '',
          user_id: item.user_id, // 为谁设置
        }),
      });
      let params = {};
      params.company_id = item.company_id;
      params.ori_user_id = this.USER_ID;
      params.des_user_id = item.user_id;
      let result = await this.$store.dispatch(
        "baseConsole/ymcompany_change_admin",
        params
      );
      if (result.success) {
        this.$message({
          message: this.$t("Successful_operation"),
          type: "success",
        });
        this.$router.push("/console/company/basic");
        // this.getAccountList();
        let res = await this.$store.dispatch("baseConsole/user_getUserInfo", {
          user_id: this.USER_ID,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.telTips{
  color: #FF0000;
  font-size: 12px;
  position: absolute;
  left: 174px;
  top: 131px;
}
.addBtn {
  margin-bottom: 10px;
}

.companyAccount /deep/ .el-table__body td {
  padding: 8px 0;
}

.companyRole /deep/ .el-table__body td {
  padding: 8px 0;
}

.add-account /deep/ .areadisabled .el-input__inner {
  background: #f5f7fa !important;
  height: 31px;
  color: #c0c4cc;
}

.add-account /deep/ .areadisabled .el-input--suffix:hover {
  color: #c0c4cc;
}

.add-account /deep/ .area-select .el-input__inner {
  width: 100px;
  background: #fff;
}

.add-account /deep/ .area-select .el-input__inner {
  background: #fff;
}

.add-account /deep/ .area-select .el-input--suffix:hover {
  background: #fff;
}

.add-account /deep/ .el-dialog__footer {
  padding: 0 20px 20px;
}

.add-account /deep/ .dialog-footer {
  margin-top: 0;
}

.add-account /deep/ .el-form {
  width: 400px;
  position: relative;
}

.accountRole {
  .companyAccount {
    height: 335px;

    .title {
      //font-size: 16px;
      //font-weight: 700;
      margin-bottom: 10px;

      div {
        float: left;
        line-height: 32px;
      }

      button {
        float: right;
      }
    }
  }

  .companyRole {
    margin-top: 9px;
    height: 334px;

    .title {
      font-size: 16px;
      font-weight: 700;
      margin-top: 16px;
      margin-bottom: 5px;

      div {
        font-size: 16px;
      }
    }
  }

  .add-account {
    .demo-ruleForm {
      .item {
        left: 20px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 7px;
      }

      .itememail {
        /deep/ .el-form-item__error {
          padding-top: 0;
          top: 90%;
        }
      }
    }
  }
}
.tableBlock {
  .userInfo {
    width: 200px;
    display: flex;
    .img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }
    .name {
      flex: 1;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .userName,
      .title {
        width: 130px;
        cursor: pointer;
      }
      .userName {
        color: #0083f6;
      }
    }
  }
}
.tabBlock {
  position: relative;
  .addBtn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.user-detail {
  .item {
    display: flex;
    margin-bottom: 20px;
    .key {
      width: 100px;
    }
    .value {
      width: 280px;
    }
  }
}
</style>
